<!-- 订单支付确认 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        custom-class="ele-dialog-form"
        :title="isUpdate?'订单支付确认':'订单支付确认'"
        @update:visible="updateVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="130px">
        <el-form-item label="支付订单">
          001
        </el-form-item>
        <el-form-item label="支付金额">
          200000元
        </el-form-item>
        <el-form-item label="支付方式">
          <el-radio v-model="radio" label="1">余额支付 可用余额(20000000)</el-radio>
          <el-radio v-model="radio" label="2">线下支付</el-radio>
        </el-form-item>
        <el-form-item label="支付方式" v-if="radio == '2'">
          <el-radio v-model="radio1" label="1">微信</el-radio>
          <el-radio v-model="radio1" label="2">支付宝</el-radio>
          <el-radio v-model="radio1" label="3">银行卡</el-radio>
        </el-form-item>
        <div v-if="radio1 == '1'">
          <el-form-item label="支付凭证" v-if="radio == '2'">
            <el-upload
                :action="action"
                :headers="headers"
                accept=".jpg, .png, jpeg"
                :on-success="successUpload"
                :limit="10"
                :file-list="fileList"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </div>
        <div v-if="radio1 == '2'">
          <el-form-item label="支付凭证">
            <el-upload
                :action="action"
                :headers="headers"
                accept=".jpg, .png, jpeg"
                :on-success="successUpload"
                :limit="10"
                :file-list="fileList"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </div>
        <div v-if="radio1 == '3'">
          <el-form-item label="对方账号">
            13103000257
          </el-form-item>
          <el-form-item label="对方账户">
            13103000257
          </el-form-item>
          <el-form-item label="开户银行">
            中国银行
          </el-form-item>
          <el-form-item label="支付凭证">
            <el-upload
                :action="action"
                :headers="headers"
                accept=".jpg, .png, jpeg"
                :on-success="successUpload"
                :limit="10"
                :file-list="fileList"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            v-auths="[`${$config.uniquePrefix}orderModule:info:followup`]"
            @click="save">确认
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import {put_shop_setup} from "@/api/system";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 是否显示线下支付
    xianxia: Boolean
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传凭证
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      radio:'',
      radio1:'',

    };
  },


  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.form.remark = '';
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },

  methods: {
    //凭证
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
